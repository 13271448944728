<template>
  <!--begin::Card-->
  <div class="card">
    <!--begin::Card header-->
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            data-kt-subscription-table-filter="search"
            class="form-control form-control-solid w-250px ps-14"
            placeholder="Search Book"
            v-model="searchBooks"
          />
        </div>
        <!--end::Search-->
      </div>
      <!--begin::Card title-->

      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Toolbar-->
        <div
          class="d-flex justify-content-end"
          data-kt-subscription-table-toolbar="base"
        >
          <!--begin::Export-->
          <button
            type="button"
            class="btn btn-light-primary me-3"
            data-bs-toggle="modal"
            data-bs-target="#kt_subscriptions_export_modal"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
            </span>
            Export
          </button>
          <!--end::Export-->

          <!--begin::Add subscription-->
          <router-link to="/html-books/create-book" class="btn btn-primary">
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr075.svg" />
            </span>
            Add Book
          </router-link>
          <!--end::Add subscription-->
        </div>
        <!--end::Toolbar-->

        <!--begin::Group actions-->
        <div
          class="d-flex justify-content-end align-items-center d-none"
          data-kt-subscription-table-toolbar="selected"
        >
          <div class="fw-bolder me-5">
            <span
              class="me-2"
              data-kt-subscription-table-select="selected_count"
            ></span>
            Selected
          </div>

          <button
            type="button"
            class="btn btn-danger"
            data-kt-subscription-table-select="delete_selected"
          >
            Delete Selected
          </button>
        </div>
        <!--end::Group actions-->
      </div>
      <!--end::Card toolbar-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-0">
      <KTDatatable
        :table-data="data"
        :table-header="table.columns"
        :current-page="table.currentPage"
        :total="total"
        :rows-per-page="table.perPage"
        :sort-lable="id"
        :loading="loading"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
        @sort="handleSortChange"
      >
        <template v-slot:cell-checkbox="{ row: user }">
          <div
            class="form-check form-check-sm form-check-custom form-check-solid"
          >
            <input class="form-check-input" type="checkbox" :value="user.id" />
          </div>
        </template>
        <template v-slot:cell-name="{ row: html_book }">
          {{ html_book.title }}
        </template>
        <template v-slot:cell-pages_count="{ row: html_book }">
          {{ html_book.pages.length }}
        </template>
        <template v-slot:cell-created_at="{ row: html_book }">
          {{ new Date(html_book.created_at).toLocaleString()}}
        </template>
        <template v-slot:cell-updated_at="{ row: html_book }">
          {{ new Date(html_book.updated_at).toLocaleString()}}
        </template>

        <template v-slot:cell-actions="{ row: html_book }">
          <a
              href="#"
              class="btn btn-sm btn-light btn-active-light-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="top-end"
          >Actions
            <span class="svg-icon svg-icon-5 m-0">
              <inline-svg src="media/icons/duotune/arrows/arr072.svg" />
            </span>
          </a>
          <!--begin::Menu-->
          <div
              class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
              data-kt-menu="true"
          >
            <!--begin::Menu item-->
            <div class="menu-item px-3">
              <router-link
                  :to="`/html-books/${html_book.id}`"
                  class="menu-link px-3"
              >View</router-link
              >
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div class="menu-item px-3">
              <a @click="downloadHtmlBook(html_book.pages)" class="menu-link px-3"
              >Download</a
              >
            </div>
            <!--end::Menu item-->
          </div>
          <!--end::Menu-->
        </template>
      </KTDatatable>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
// import NewCouponModal from "../components/modals/forms/NewCouponModal.vue";
import ApiService from "../core/services/ApiService.ts";
import Swal from "sweetalert2/dist/sweetalert2.js";

// import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";

import RemoteTable from "@/components/RemoteTable.vue";
import useDebounceRef from "../composables/useDebounceRef";
import moment from "moment/moment";
import {MenuComponent} from "../assets/ts/components";

export default {
  name: "Html Books",
  components: { KTDatatable },
  updateCouponMode: false,
  setup() {
    let user = [];
    const total = ref(0);
    const table = reactive({
      height: 411,
      data: [],
      columns: [
        { key: "checkbox", sortable: true, sortingField: "id" },
        {
          key: "name",
          name: "Name",
          sortable: false,
        },
        {
          key: "pages_count",
          name: "pages_count",
          sortable: true,
        },
        {
          key: "created_at",
          name: "created_at",
          sortable: true,
        },
        {
          key: "updated_at",
          name: "update_at",
          sortable: true,
        },
        {
          key: "actions",
          name: "Actions",
          sortable: false,
        },
      ],
      remote: {
        url: "admin/html-book",
        method: "GET",
      },
      pageSizes: [10, 30, 50, 100],
      currentPage: 1,
      perPage: 10,
      sort: { columnName: "id", order: "desc" },
    });
    const data = ref(user);
    const loading = ref(false);
    const loadHtmlBooks = () => {
      loading.value = true;
      ApiService.get(table.remote.url, null, {
        params: {
          page: table.currentPage,
          perPage: table.perPage,
          search: searchBooks.value,
          sort: table.sort,
        },
      })
        .then(({ data: dataC }) => {
          console.log("datadatadatadatadatadatadatadata");
          console.log(dataC.data);
          data.value = dataC.data;
          table.data = dataC.data;
          total.value = dataC.meta.total;
          console.log("total");
          console.log(total);
          console.log("total");
        })
        .catch((err) => {
          console.log("errerrerrerrerrerr");
          console.log(err);
          console.log("errerrerrerrerrerr");
        })
        .finally(() => {
          loading.value = false;
          MenuComponent.reinitialization();
        });
    };

    let searchBooks = useDebounceRef("", 400);

    watch(searchBooks, (newQuery) => {
      loadHtmlBooks();
    });
    onMounted(() => {
      setCurrentPageBreadcrumbs("User List", ["Apps", "Html Books"]);
      // this.getPlans();
      loadHtmlBooks();
    });
    return { data, table, total, loading, loadHtmlBooks, searchBooks };
  },
  // mounted() {
  //   this.getPlans();
  //   this.loadHtmlBooks();
  // },
  data: function () {
    return {
      selectedCoupon: {},
      currentCoupon: {},
      plans: [],
    };
  },
  methods: {
    getPlans() {
      ApiService.get("admin/plans", "", {
        params: {
          all: true,
        },
      }).then(({ data }) => {
        this.plans = data;
      });
    },

    handleSizeChange(perPage) {
      this.table.perPage = perPage;
      this.loadHtmlBooks();
    },
    handleSortChange(sort) {
      console.log(sort);
      this.table.sort = sort;
      this.loadHtmlBooks();
    },
    handleCurrentChange(page) {
      this.table.currentPage = page;
      this.loadHtmlBooks();
      console.log("page");
      console.log(page);
      console.log("page");
    },
    downloadHtmlBook(pages) {
      let doc = document.implementation.createHTMLDocument("New Document");
      pages.forEach((page, index) => {
        let section = doc.createElement("section");
        section.setAttribute("id", `chapter-${index + 1}`);
        let h3 = doc.createElement("h3");
        h3.setAttribute('dir','rtl')
        h3.textContent = page.title;
        let div = doc.createElement("div");
        div.innerHTML = page.description;
        div.setAttribute('dir','rtl')
        console.log(div);
        section.appendChild(h3);
        section.appendChild(div);
        try {
          doc.body.appendChild(section);
        } catch (e) {
          console.log(e);
        }
      });
      let blob = new Blob([`<html>${doc.body.outerHTML}</html>`], {
        type: "text/html",
      });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download =
          "new-book" + "-" + moment().format("YYYY-MM-DD-HHmm") + ".html";
      link.click();
    },

    getPercentage(item) {
      return ((100 * item.uses) / item.max_uses).toFixed(2);
    },
    getColor(item) {
      const percentage = this.getPercentage(item);
      if (percentage <= 30) return "primary";
      if (percentage <= 60) return "success";
      if (percentage <= 90) return "warning";
      return "danger";
    },
  },
};
</script>
